import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Fixed } from "@components/feature-list";

const DigitalRetailerCards = ({ getStarted, reverse }) => {
  const images = useStaticQuery(graphql`
    query {
      xs: file(name: { eq: "digital-retailer-cards" }) {
        sharp: childImageSharp {
          fixed(width: 438) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      sm: file(name: { eq: "digital-retailer-cards" }) {
        sharp: childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      md: file(name: { eq: "digital-retailer-cards" }) {
        sharp: childImageSharp {
          fixed(width: 560) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const sources = [
    images.xs.sharp.fixed,
    {
      ...images.sm.sharp.fixed,
      media: `(min-width: 640px AND max-width: 767px)`,
    },
    {
      ...images.md.sharp.fixed,
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <Fixed
      id="physical-eftpos"
      description={<Description getStarted={!!getStarted}/>}
      image={sources}
      reverse={!reverse} />
  );
}

const Description = () => (
  <div>
    <div>
      <h2>
        <span className="block text-base text-highlight font-semibold tracking-wide uppercase">
          Self service delivery platform
        </span>

        <span className="block text-3xl font-bold tracking-tight text-gray-900 mt-1">
          Digital Retailer Store
        </span>
      </h2>
      <p className="mt-4 text-lg text-gray-500">
        Full service digital delivery of emailed retail store cards that offer choice and instant
        delivery. Deliver to any mobile device enabling in-store or online redemption. Cards have
        flexible values between $5 and $1000. Perfect for B2B campaigns for loyalty and retention,
        and B2C communities for fundraising.
      </p>
    </div>
  </div>
);

export default DigitalRetailerCards;
